import * as PIXI from 'pixi.js'
import Trigger from '@/timeline/Trigger'
import store from '@/store'
import { Tool } from '@/tools'
import { secondsToTime, snapTime } from '@/util'
import { defaults } from './defaults'

export default class TimelineManager {
  constructor(app) {
    this.app = app
    this.width = app.view.width
    this.height = app.view.height

    this.app.stage.interactive = true
    this.app.stage.hitArea = app.screen
    this.container = new PIXI.Container()
    this.app.stage.addChild(new PIXI.Graphics().beginFill(0xffffff).drawRect(0, 0, this.width, defaults.headerHeight))
    this.container.interactive = true

    this.app.stage.on('pointerdown', this.pointerDown, this)
    this.app.stage.on('pointerup', this.pointerUp, this)
    this.app.stage.on('pointermove', this.pointerMove, this)
    this.app.stage.on('pointerupoutside', this.pointerUp, this)
    this.app.stage.on('wheel', this.onWheel, this)

    this.majorTextStyle = new PIXI.TextStyle({ fontFamily: 'Courier', fontSize: 14, fill: defaults.majorGrid.colour })
    this.minorTextStyle = new PIXI.TextStyle({ fontFamily: 'Courier', fontSize: 12, fill: defaults.minorGrid.colour })

    this.zoom = this.container.scale.x = 12
    this.majorGridIndex = defaults.majorGrid.times.indexOf(10)
    this.majorGridMaxWidth = defaults.majorGrid.times[this.majorGridIndex + 1] * this.zoom
    this.majorGridMinWidth = defaults.majorGrid.times[this.majorGridIndex] * this.zoom

    this.isDragging = false
    this.dragStart = undefined
    this.isSelecting = false
    this.forcePan = false

    this.vertLineGraphics = new PIXI.Graphics()
    this.horizLineGraphics = new PIXI.Graphics()
    this.selectionBox = new PIXI.Graphics()
    this.endBox = new PIXI.Graphics()

    this.app.stage.addChild(this.vertLineGraphics)
    this.app.stage.addChild(this.container)
    this.app.stage.addChild(this.horizLineGraphics)
    this.app.stage.addChild(this.selectionBox)
    this.app.stage.addChild(this.endBox)

    this.drawGrid()

    this.selected = []
    this.copySelected = false

    window.addEventListener('keydown', this.keyDown.bind(this))
    window.addEventListener('keyup', this.keyUp.bind(this))
  }

  resize(width, height) {
    this.width = width
    this.height = height
    this.draw()
  }

  timeTrackAtPoint(x, y, snapTime = true) {
    const time = (x - this.container.x) / this.zoom
    const track = Math.max(0, Math.floor((y - defaults.headerHeight) / this.trackHeight))
    return [time, track]
  }

  keyDown(event) {
    const { currentTool } = store.state
    const { key, code } = event
    if (currentTool === Tool.Select && (code === 'Backspace' || code === 'Delete')) {
      this.deleteTriggers(this.selected)
      this.selected = []
    }
    if (key === 'Alt') {
      this.copySelected = true
    }
  }

  keyUp(event) {

  }

  pointerDown(event) {
    this.isDragging = true
    const { x, y } = event.screen
    const [time, track] = this.timeTrackAtPoint(x, y, false)
    this.dragStart = { x, y, time, track, containerX: this.container.x }
    const { currentTool } = store.state

    if (event.button === 1) { // wheel button
      this.forcePan = true
    } else {
      const trigger = this.onTrigger(track, time)
      if (currentTool === Tool.Add) {
        const { triggerSpeed } = store.state
        if (trigger && trigger.speed !== triggerSpeed) {
          trigger.speed = triggerSpeed
        } else if (!trigger) {
          this.addTrigger(time, track, triggerSpeed, true)
        }
      } else if (currentTool === Tool.Select) {
        if (this.isSelecting && trigger && trigger.selected) {
          this.isSelecting = false
          if (this.copySelected) {
            const newTriggers = []
            this.selected.forEach((trigger, i) => {
              const { time, track, speed } = trigger
              const newTrigger = this.addTrigger(time, track, speed, false, false)
              newTrigger.selected = true
              newTriggers.push(newTrigger)
              trigger.selected = false
              trigger.draw()
              newTrigger.draw()
            })
            this.selected = newTriggers
          }
          this.selected.forEach(trigger => trigger.cacheValues())
        } else {
          this.isSelecting = true
          this.selectTriggers(x, y)
        }
      } else if (currentTool === Tool.Delete) {
        trigger && this.deleteTriggers([trigger])
      }
    }
  }

  pointerUp(event) {
    this.isDragging = false
    this.forcePan = false
    if (store.state.currentTool === Tool.Select) {
      this.selectionBox.clear()
      if (!this.isSelecting || this.copySelected) {
        this.removeDuplicates()
        store.commit('pushState')
      }
      this.copySelected = false
    }
  }

  pointerMove(event) {
    const { screen } = event
    const { dragStart } = this
    if (this.isDragging) {
      if (store.state.currentTool === Tool.Pan || this.forcePan) {
        const dx = (dragStart.x - screen.x)
        this.container.x = Math.min(0, dragStart.containerX - dx)
        this.drawGrid()
      } else if (store.state.currentTool === Tool.Select) {
        if (this.isSelecting) {
          const x = screen.x < dragStart.x ? screen.x : dragStart.x
          const y = screen.y < dragStart.y ? screen.y : dragStart.y
          const width = Math.abs(dragStart.x - screen.x)
          const height = Math.abs(dragStart.y - screen.y)
          this.selectionBox.clear()
          this.selectionBox.beginFill(0xcccccc, 0.3).drawRect(x, y, width, height)
          this.selectTriggers(x, y, width, height)
        } else {
          const [time, track] = this.timeTrackAtPoint(screen.x, screen.y)
          const timeDiff = time - dragStart.time
          const trackDiff = track - dragStart.track
          this.selected.forEach((trigger) => {
            trigger.move(timeDiff, trackDiff, this.minorGridTime)
          })
        }
      }
    }
  }

  onWheel(event) {
    const { deltaY, screen } = event
    const s = 1 - deltaY * defaults.zoom.multiplier
    this.zoom = Math.min(defaults.zoom.max, Math.max(defaults.zoom.min, this.zoom * s))
    const worldPos = (screen.x - this.container.x) / this.container.scale.x
    this.container.scale.x = this.zoom
    this.container.x = Math.min(0, screen.x - worldPos * this.zoom)
    this.drawGrid()
  }

  get majorGridTime() {
    return defaults.majorGrid.times[this.majorGridIndex]
  }

  get minorGridTime() {
    return this.majorGridTime / defaults.minorGrid.count
  }

  get trackHeight() {
    const { toolbarHeight, cannonNumbers } = store.state
    return (window.innerHeight - toolbarHeight - defaults.headerHeight) / cannonNumbers.length
  }

  calcMajorGridStep(zoom) {
    let majorStep = this.majorGridTime * zoom
    while (majorStep > this.majorGridMaxWidth && this.majorGridIndex >= 1) {
      this.majorGridMaxWidth = this.majorGridTime * zoom
      this.majorGridMinWidth = defaults.majorGrid.times[this.majorGridIndex - 1] * zoom
      this.majorGridIndex = Math.max(this.majorGridIndex - 1, 0)
      majorStep = this.majorGridTime * zoom
    }
    while (majorStep < this.majorGridMinWidth && this.majorGridIndex < defaults.majorGrid.times.length - 1) {
      this.majorGridMaxWidth = defaults.majorGrid.times[this.majorGridIndex + 1] * zoom
      this.majorGridMinWidth = this.majorGridTime * zoom
      this.majorGridIndex = Math.min(this.majorGridIndex + 1, defaults.majorGrid.times.length - 1)
      majorStep = this.majorGridTime * zoom
    }
    return majorStep
  }

  drawGrid() {
    const { toolbarHeight, duration } = store.state

    this.vertLineGraphics.clear()
    this.vertLineGraphics.removeChildren()
    this.vertLineGraphics.lineStyle(1.0, defaults.majorGrid.colour)

    const majorStep = this.calcMajorGridStep(this.zoom)
    const start = Math.abs(this.container.x) % majorStep
    const firstMajorTime = (Math.abs(this.container.x) - start) / this.zoom
    const n = this.width / majorStep
    const minorStep = majorStep / defaults.minorGrid.count

    for (let i = 0; i < n + 1; i++) {
      const x = i * majorStep - start
      this.vertLineGraphics.lineStyle(1, defaults.majorGrid.colour)
      this.vertLineGraphics.moveTo(x, defaults.headerHeight - 3)
      this.vertLineGraphics.lineTo(x, window.innerHeight - toolbarHeight)

      this.vertLineGraphics.lineStyle(1, defaults.minorGrid.colour)

      const seconds = Math.round(firstMajorTime + i * this.majorGridTime)
      const text = new PIXI.Text(secondsToTime(seconds, 1), this.majorTextStyle)
      text.position.set(x + 2, 3)
      this.vertLineGraphics.addChild(text)

      for (let j = 1; j < defaults.minorGrid.count; j++) {
        const xx = x + j * minorStep
        this.vertLineGraphics.moveTo(xx, defaults.headerHeight)
        this.vertLineGraphics.lineTo(xx, window.innerHeight - toolbarHeight)
        // if (j % defaults.minorGrid.textDisplayStep === 0) {
        //   seconds = (i + j / defaults.minorGrid.count) * this.majorGridTime
        //   const half = new PIXI.Text(seconds, this.minorTextStyle)
        //   half.position.set(xx - 4, 3)
        //   this.vertLineGraphics.addChild(half)
        // }
      }
    }

    this.horizLineGraphics.clear()
    this.horizLineGraphics.lineStyle(1, 0x999999)
    for (let y = 0; y < window.innerHeight - toolbarHeight; y += this.trackHeight) {
      this.horizLineGraphics.moveTo(0, defaults.headerHeight + y)
      this.horizLineGraphics.lineTo(1e9, defaults.headerHeight + y)
    }

    this.endBox.clear()
    const durationBoxX = duration * this.zoom + this.container.x
    this.endBox.beginFill(0x000000, 0.8).drawRect(durationBoxX, defaults.headerHeight, 1e6, this.height - defaults.headerHeight)
  }

  draw() {
    this.drawGrid()
    for (const trigger of this.triggers) {
      trigger.draw(this.trackHeight, trigger.colour)
    }
  }

  get triggers() {
    return this.container.children
  }

  addTrigger(time, track, triggerSpeed, snapToGrid = false, pushState = true) {
    if (snapToGrid) {
      time = snapTime(time, this.minorGridTime)
    }
    const trigger = new Trigger(time, track, triggerSpeed, this.trackHeight)
    this.container.addChild(trigger)

    if (pushState) {
      this.removeDuplicates()
      store.commit('pushState')
    }

    store.state.numTriggers = this.triggers.length
    return trigger
  }

  deleteTriggers(triggers, pushState = true) {
    this.container.removeChild(...triggers)
    store.state.numTriggers = this.triggers.length
    pushState && store.commit('pushState')
  }

  setTriggers(triggers) {
    this.container.removeChildren()
    for (const trigger of triggers) {
      this.container.addChild(trigger)
    }
    store.state.numTriggers = this.triggers.length
    this.selected = []
    this.draw()
  }

  findTriggers(startTrack, endTrack, startTime, endTime) {
    return this.triggers.filter(trigger => {
      return trigger.inside(startTrack, endTrack, startTime, endTime)
    })
  }

  onTrigger(track, time) {
    const triggers = this.findTriggers(track, track, time, time)
    return triggers.length > 0 ? triggers[0] : null
  }

  selectTriggers(x, y, width = 1, height = 1) {
    const [startTime, startTrack] = this.timeTrackAtPoint(x, y)
    const [endTime, endTrack] = this.timeTrackAtPoint(x + width, y + height)

    const toRedraw = new Set()
    this.selected.forEach((trigger) => {
      trigger.selected = false
      toRedraw.add(trigger)
    })

    this.selected = this.findTriggers(startTrack, endTrack, startTime, endTime)

    this.selected.forEach((trigger) => {
      trigger.selected = true
      toRedraw.add(trigger)
    })

    toRedraw.forEach((trigger) => {
      trigger.draw()
    })
  }

  load(triggers) {
    this.container.removeChildren()
    triggers.forEach((track, trackIndex) => {
      track.forEach(({ time, speed }) => {
        this.addTrigger(time, trackIndex, speed, false, false)
      })
    })
    this.zoomToFit()
    this.draw()
  }

  reorder(oldNumbers, newNumbers) {
    const oldMap = Object.fromEntries(oldNumbers.map((e, i) => [i, e]))
    const newMap = Object.fromEntries(newNumbers.map((e, i) => [e, i]))
    for (const trigger of this.triggers) {
      trigger.track = newMap[oldMap[trigger.track]]
    }
  }

  zoomToFit() {
    const { duration } = store.state
    const oldZoom = this.zoom
    this.container.scale.x = this.zoom = this.width / duration
    this.container.x = 0

    // this is a bit of nasty hack, but we incrementally do the grids
    const step = this.zoom - oldZoom > 0 ? 1 : -1
    const count = Math.abs(this.zoom - oldZoom)
    for (let i = 0; i < count; i++) {
      this.calcMajorGridStep(oldZoom + step * i)
    }
    this.calcMajorGridStep(this.zoom)
  }

  removeDuplicates() {
    const toDelete = []
    for (let i = 0; i < this.triggers.length; i++) {
      for (let j = 0; j < i; j++) {
        const a = this.triggers[i]
        const b = this.triggers[j]
        if (a.overlaps(b)) {
          toDelete.push(b)
        }
      }
    }
    this.deleteTriggers(toDelete, false)
  }
}
