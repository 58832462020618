<template>
  <div class="button is-white">
    <mdicon name="upload" @click="$refs.input.click()" />
    <input ref="input" type="file" accept=".json"
           @change="change">
  </div>
</template>

<script>
export default {
  name: 'Upload',
  methods: {
    change(inputEvent) {
      const reader = new FileReader()
      reader.addEventListener('load', (loadEvent) => {
        const json = JSON.parse(loadEvent.target.result)
        this.$store.dispatch('load', json)
      })
      const { target } = inputEvent
      reader.readAsText(target.files[0])
      target.value = null
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
input {
  display: none;
}
</style>
