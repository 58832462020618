<template>
  <div class="modal" :class="{ 'is-active': isActive }">
  <div class="modal-background"></div>
  <div class="modal-content">
    <button class="modal-close is-large" aria-label="close"
            @click="close">
    </button>
    <!-- Any other Bulma elements you want -->
    <div class="box">
      <h1 class="title is-4">Create</h1>
      <div class="row">
        <p>Cannons:</p>
        <input type="text" v-model="cannonsInput"
               class="input"
               placeholder="e.g. 1, 2-3, even, odd, all"/>
        <span v-if="cannons.length > 0" class="cannon-list">
          {{ cannons }}
        </span>
      </div>
      <div class="row">
        <p>Speed:</p>
        <div class="select">
          <select v-model="speed">
            <option v-for="option in speedOptions" :key="option">
              {{ option }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <p>Start:</p>
        <input type="time" v-model="startTime" :disabled="randomStart"/>
        <span>or</span>
        <label class="checkbox">
          <input type="checkbox" v-model="randomStart">
          Random
        </label>
      </div>
      <div class="row">
        <p>Repeat:</p>
        <input type="number" v-model="repeat" min="0" class="input" />
        <div v-if="!randomStart">
          <span>every</span>
          <input type="time" v-model="repeatInterval" :disabled="randomStart"/>
        </div>
      </div>
      <div class="row">
        <p>Stagger:</p>
        <input type="time" v-model="staggerTime" />
      </div>
      <div class="buttons is-right">
        <button class="button" @click="close">Cancel</button>
        <button class="button is-info" @click="add">Add</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { TriggerSpeed } from '@/tools'
import { mapState } from 'vuex'
import { timeToSeconds } from '@/util'

export default {
  name: 'WandModal',
  props: {
    isActive: Boolean
  },
  data() {
    return {
      cannonsInput: '',
      speed: null,
      startTime: '00:00',
      randomStart: false,
      repeat: 0,
      repeatInterval: '00:10',
      staggerTime: '00:00',
      speedOptions: Object.values(TriggerSpeed)
    }
  },
  computed: {
    ...mapState(['cannonNumbers', 'duration']),
    cannons() {
      const parse = (token) => {
        if (token === 'all') {
          return this.cannonNumbers
        } else if (token === 'even') {
          return this.cannonNumbers.filter(n => n % 2 === 0)
        } else if (token === 'odd') {
          return this.cannonNumbers.filter(n => n % 2 === 1)
        } else if (token.match(/-/)) {
          const parts = token.split('-').map(t => t.trim())
          const start = +parts[0]
          const end = +parts[parts.length - 1]
          if (!isNaN(start) && !isNaN(end) && end > start) {
            const length = end - start + 1
            return Array(length).fill(start).map((e, i) => e + i)
          }
        }
        const number = +token
        if (token.length > 0 && !isNaN(number)) {
          return [number]
        }
        return []
      }
      const allowed = new Set(this.cannonNumbers)
      const numbers = []
      this.cannonsInput
        .split(',')
        .map(t => t.trim())
        .map(parse)
        .forEach(ns => ns.forEach(n => {
          if (allowed.has(n) && !numbers.includes(n)) {
            numbers.push(n)
          }
        }))
      return numbers
    }
  },
  methods: {
    close() {
      this.$store.commit('setTool', 'select')
    },
    add() {
      const manager = this.$store.state.timeline
      const tracks = Object.fromEntries(this.cannonNumbers.map((n, i) => [n, i]))
      const repeat = timeToSeconds(this.repeatInterval)
      const stagger = timeToSeconds(this.staggerTime)
      let time = timeToSeconds(this.startTime)

      for (let i = 0; i <= this.repeat; i++) {
        if (this.randomStart) {
          time = Math.round(Math.random() * this.duration)
        }
        this.cannons.forEach((number, j) => {
          const track = tracks[number]
          if (this.randomStart && stagger === 0) {
            time = Math.round(Math.random() * this.duration)
          }
          manager.addTrigger(time + j * stagger, track, this.speed, false, false)
        })
        time += repeat
      }
      this.$store.commit('pushState')
      this.close()
    }
  },
  created() {
    this.speed = this.speedOptions[0]
  }
}
</script>

<style scoped lang="scss">
.modal-content {
  position: relative;
}

div.row {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  p {
    width: 4rem;
  }

  label {
    margin: 0 0.5rem;
  }

  input[type=text] {
    width: 13rem;
  }

  input[type=number] {
    width: 4rem;
  }

  span {
    margin: 0 1rem;
  }

  .cannon-list {
    font-size: 0.75rem;
    color: #666666;
  }
}

p:not(:first-child) {
  margin: 0 0.5rem;
}
p:first-child {
  margin-right: 0.5rem;
}
</style>
