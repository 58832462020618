<template>
  <div class="sidebar" :style="{ marginTop: `${headerHeight}px` }">
    <draggable v-model="cannonNumbers" item-key="id">
      <template #item="{element}">
        <track-menu :style="{ height: trackHeight }"
                    :track-index="element.index"
                    @redraw="$emit('redraw')">
        </track-menu>
      </template>
    </draggable>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import draggable from 'vuedraggable'
import TrackMenu from './TrackMenu.vue'
import { defaults } from '@/timeline/defaults'

export default {
  name: 'Sidebar',
  components: { draggable, TrackMenu },
  props: {
    trackHeight: String
  },
  computed: {
    ...mapState(['timeline']),
    cannonNumbers: {
      get() {
        const { cannonNumbers } = this.$store.state
        return cannonNumbers.map((n, i) => ({ id: i, number: n, index: i }))
      },
      set(v) {
        this.$store.dispatch('reorderNumbers', v.map(n => n.number))
      }
    },
    headerHeight() {
      return defaults.headerHeight
    }
  }
}
</script>

<style lang="scss" scoped>
$sidebar-width: 50px;
    div.sidebar {
    width: $sidebar-width;
  }
</style>
