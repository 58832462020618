<template>
  <div class="dropdown is-right"
       :class="{ 'is-active': isActive }">
    <div class="dropdown-trigger" @click="isActive = !isActive">
      <div class="button is-white">
        <mdicon name="content-save" size="20"/>
      </div>
    </div>
    <div class="dropdown-menu track-menu" role="menu">
      <div class="dropdown-content">
        <a v-for="{ name, icon } in options" :key="name"
           class="dropdown-item"
           @click="save(name)">
          <mdicon :name="icon" size="18"></mdicon>
          <span>{{ name }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import download from '@/download'

export default {
  name: 'Save',
  data() {
    return {
      isActive: false,
      options: [
        { name: 'Canary', icon: 'bird' },
        { name: 'Timing', icon: 'clock' },
        { name: 'NCOD', icon: 'data-matrix' },
        { name: 'Zip', icon: 'folder-zip' }
      ]
    }
  },
  methods: {
    save(name) {
      download(name)
      this.isActive = false
    }
  }
}
</script>

<style scoped>

</style>
