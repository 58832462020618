import JSZip from 'jszip'
import store from '@/store'
import { TriggerSpeed } from '@/tools'

function saveFile(data, filename) {
  const a = document.createElement('a')
  a.href = URL.createObjectURL(data)
  a.download = filename
  a.click()
  setTimeout(() => a.remove(), 1000)
}

function makeFilename(title, extension) {
  const slug = title.replace(' ', '-').toLowerCase()
  return `${slug}.${extension}`
}

function canary() {
  const { cannonNumbers, title, timeline, fireDurations, duration } = store.state

  const json = { title, duration }
  json.fire_durations = { ...fireDurations }
  json.tracks = cannonNumbers.map((number, track) => {
    const triggers = timeline.triggers
      .filter(trigger => trigger.track === track)
      .map(trigger => {
        const { time, speed } = trigger
        return { time, speed }
      })
    return { number, triggers }
  })

  const content = JSON.stringify(json, null, 2)
  const data = new Blob([content], { type: 'application/json' })
  const filename = makeFilename(title, 'json')

  return new Promise((resolve) => resolve({ filename, data }))
}

function timing() {
  const { cannonNumbers, timeline, title } = store.state

  const triggers = [...timeline.triggers]
  const content = triggers
    .sort((a, b) => a.time - b.time)
    .map(trigger => {
      const number = cannonNumbers[trigger.track]
      return [number, trigger.time, trigger.speed].join('\t')
    })
    .join('\r\n')

  const data = new Blob(['number\ttime\tspeed\r\n' + content], { type: 'text/tab-separated-values' })
  const filename = makeFilename(title, 'txt')

  return new Promise((resolve) => resolve({ filename, data }))
}

function ncod() {
  const { cannonNumbers, title, timeline, fireDurations, duration, frameRate } = store.state
  const fireDurationsSeconds = Object.fromEntries(Object.entries(fireDurations)
    .map(([k, v]) => [k, v / 1000]))

  const numChannels = Math.max(...cannonNumbers) * 4
  const fps = frameRate
  const frameDelay = 1000 / fps * 100
  const frames = Array(duration * fps + 1).fill(null).map(_ => new Uint8Array(numChannels))

  const headerLength = 512
  const frameLength = 19 + numChannels
  const fileLength = headerLength + frameLength * frames.length
  const fileContents = new Uint8Array(fileLength)
  fileContents.fill(0)

  const insert = (index, other) => {
    const is8 = other.BYTES_PER_ELEMENT === 1
    const buf = is8 ? other : new Uint8Array(other.buffer)
    for (let i = 0; i < buf.length; i++) {
      fileContents[index + i] = buf[is8 ? i : buf.length - i - 1]
    }
  }

  const endTime = new Uint32Array([Math.round(duration * 1000)])
  insert(4, endTime)
  insert(8, endTime)
  insert(12, new Uint16Array([numChannels]))

  const addNote = (time, duration, channels) => {
    const startIndex = Math.round(time * fps)
    const endIndex = startIndex + Math.round(duration * fps)
    for (let i = startIndex; i < endIndex; i++) {
      const frame = frames[i]
      for (const channel of channels) {
        frame[channel] = 0xff
      }
    }
  }

  for (const trigger of timeline.triggers) {
    let time = trigger.time
    const baseChannel = (cannonNumbers[trigger.track] - 1) * 4
    addNote(time, fireDurationsSeconds.fog, [baseChannel + 3])
    addNote(time, fireDurationsSeconds.reverse, [baseChannel + 1, baseChannel + 2])
    time += fireDurationsSeconds.reverse + fireDurationsSeconds.settle
    const forwardChannels = [baseChannel]
    if (trigger.speed === TriggerSpeed.Fast) {
      forwardChannels.push(baseChannel + 2)
    }
    addNote(time, fireDurationsSeconds.forward, forwardChannels)
  }

  const frameDmx = (new TextEncoder()).encode('framedmx')

  frames.forEach((frame, i) => {
    const offset = headerLength + i * frameLength
    const absTime = i * frameDelay / 100
    insert(offset, frameDmx)
    insert(offset + 9, new Uint16Array([numChannels]))
    insert(offset + 11, new Uint32Array([frameDelay]))
    insert(offset + 15, new Uint32Array([absTime]))
    insert(offset + 19, frame)
  })

  const data = new Blob([fileContents], { type: 'octect/stream' })
  const filename = makeFilename(title, 'NCOD')

  return new Promise((resolve) => resolve({ filename, data }))
}

async function zip() {
  const z = new JSZip()
  for (const [name, func] of Object.entries(functions)) {
    if (name !== 'zip') {
      const { filename, data } = await func()
      z.file(filename, data)
    }
  }
  const data = await z.generateAsync({ type: 'blob' })
  const filename = makeFilename(store.state.title, 'zip')

  return { filename, data }
}

const functions = { canary, timing, ncod, zip }

export default async function(name) {
  const func = functions[name.toLowerCase()]
  if (func) {
    const { filename, data } = await func()
    saveFile(data, filename)
  }
}
