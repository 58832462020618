export const defaults = {
  maxZoom: 500,
  headerHeight: 20,
  zoom: {
    multiplier: 0.002,
    min: 0.2,
    max: 400
  },
  targetNumMajorGridLines: 8,
  majorGrid: {
    times: [1, 2, 5, 10, 20, 60, 120, 300, 600],
    colour: 0x777777
  },
  minorGrid: {
    count: 10,
    textDisplayStep: 5,
    colour: 0xdddddd
  },
  trigger: {
    colours: {
      slow: 0x3d1fa6,
      fast: 0x209bee,
      selected: 0x555555
    }
  }
}
