import { Graphics } from 'pixi.js'
import { snapTime } from '@/util'
import { defaults } from './defaults'
import { TriggerSpeed } from '@/tools'
import store from '@/store'

export default class Trigger extends Graphics {
  constructor(time, track, speed, height, draw = false) {
    super()
    this._height = height
    this.time = time
    this.track = track
    this.selected = false
    this.cache = {}
    this.speed = speed

    if (draw) {
      this.draw(height)
    }
  }

  get time() {
    return this.x
  }

  set time(t) {
    this.x = t
  }

  get track() {
    return this._track
  }

  set track(t) {
    this._track = t
    this.setY()
  }

  get speed() {
    return this._speed
  }

  set speed(speed) {
    this._speed = speed
    this.draw()
  }

  get colour() {
    if (this.selected) {
      return defaults.trigger.colours.selected
    }
    return defaults.trigger.colours[this.speed]
  }

  get start() {
    return this.time
  }

  get end() {
    return this.time + store.getters.triggerDuration
  }

  setY() {
    this.y = defaults.headerHeight + this._track * this._height
  }

  draw(height) {
    if (height) {
      this._height = height
      this.setY()
    }
    this.clear()
    this.beginFill(this.colour).drawRect(0, 0, store.getters.triggerDuration, this._height)
  }

  inside(startTrack, endTrack, startTime, endTime) {
    return (
      startTrack <= this.track &&
      endTrack >= this.track &&
      startTime < this.end &&
      endTime > this.start
    )
  }

  overlaps(other) {
    return (this.track === other.track &&
      ((other.start >= this.start && other.start <= this.end) ||
      (this.start >= other.start && this.start <= other.end)))
  }

  cacheValues(s) {
    this.cache.time = this.time
    this.cache.track = this.track
  }

  move(timeDiff, trackDiff, snapDuration) {
    this.time = snapTime(this.cache.time + timeDiff, snapDuration)
    this.track = this.cache.track + trackDiff
  }

  clone() {
    return new Trigger(this.time, this.track, this.speed, this._height, false)
  }
}
