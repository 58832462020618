<template>
<div class="toolbar">
  <div class="buttons are-small">
    <mdicon v-for="(tool, name) in tools" :key="name"
              :name="tool.icon" size="20"
              class="button is-light" :class="{ 'is-active': currentTool === name }"
              :title="tool.shortcut"
              @click="select(name)" />
    <div class="trigger-speed" >
      <button v-if="currentTool === Tool.Add"
              class="button is-rounded is-bold"
              :class="triggerSpeedColour"
              @click="selectSpeed()">
              {{ triggerSpeedText }}
      </button>
    </div>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { Tool, tools, TriggerSpeed } from '@/tools'

export default {
  name: 'Toolbar',
  data() {
    return {
      tools,
      Tool,
      TriggerSpeed
    }
  },
  computed: {
    ...mapState(['currentTool', 'triggerSpeed']),
    triggerSpeedText() {
      return this.triggerSpeed.toUpperCase()
    },
    triggerSpeedColour() {
      return this.triggerSpeed === TriggerSpeed.Slow ? 'is-primary' : 'is-info'
    }
  },
  methods: {
    select(name) {
      this.$store.commit('setTool', name)
    },
    selectSpeed() {
      const newSpeed = this.triggerSpeed === TriggerSpeed.Slow ? TriggerSpeed.Fast : TriggerSpeed.Slow
      this.$store.commit('setTriggerSpeed', newSpeed)
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/bulma.scss';

div.toolbar {
  padding: 10px;
  display: flex;
  position: relative;

  div.trigger-speed {
    position: absolute;
    right: -50px;

    .button {
      width: 52px;
    }
  }
}
</style>
