<template>
  <div class="timeline is-flex">
    <sidebar :track-height="trackHeight()"
             @redraw="manager.draw()">
    </sidebar>
    <canvas ref="canvas" :style="{ height: canvasHeight() }"></canvas>
  </div>
</template>

<script>
import * as PIXI from 'pixi.js'
import TimelineManager from '@/timeline/TimelineManager'
import { mapState } from 'vuex'
import Sidebar from './Sidebar.vue'

export default {
  name: 'Timeline',
  components: { Sidebar },
  data() {
    return {}
  },
  computed: {
    ...mapState(['cannonNumbers', 'timeline', 'toolbarHeight']),
    ...mapState({ manager: 'timeline' })
  },
  methods: {
    trackHeight() {
      return this.manager ? `${this.manager.trackHeight}px` : '0'
    },
    canvasHeight() {
      return `${window.innerHeight - this.toolbarHeight}px`
    }
  },
  mounted() {
    const app = new PIXI.Application({
      background: '#fafafa',
      view: this.$refs.canvas
    })

    app.resizeTo = this.$refs.canvas
    this.$store.state.timeline = new TimelineManager(app)

    this.$store.commit('pushState')

    window.addEventListener('resize', () => {
      const { width, height } = this.$refs.canvas
      this.manager.resize(width, height)
      this.$forceUpdate()
    })
  }
}
</script>

<style lang="scss" scoped>
$sidebar-width: 50px;

canvas {
  width: calc(100% - $sidebar-width);
}
</style>
