<template>
 <div ref="app">
  <header class="is-flex is-align-content-center">
    <div class="title-container">
      <input v-if="isEditingTitle" type="text"
             v-model="$store.state.title"
             class="input"
             @keydown.enter="isEditingTitle = false">
      <h3 v-else class="title is-5" @click="isEditingTitle = true">{{ $store.state.title }}</h3>
    </div>
    <input type="time" v-model="durationTime" class="ml-4" />
    <span class="spacer"></span>
    <toolbar></toolbar>
    <span class="spacer"></span>
    <upload></upload>
    <save></save>
    <settings></settings>
  </header>
  <timeline></timeline>
  <wand-modal :is-active="currentTool === 'wand'"></wand-modal>
  <footer>
    <h1>
      Canary
      <small>{{ version }}</small>
    </h1>
    <span class="spacer"></span>
    <span>{{ numTriggers }} trigger{{ numTriggers === 1 ? '' : 's' }}</span>
    <span>{{ triggersPerHour}} triggers per hour</span>
    <span>{{ fogDuration }} seconds fog per hour</span>
  </footer>
 </div>
</template>

<script>
import Timeline from '@/components/Timeline'
import Toolbar from '@/components/Toolbar'
import { tools } from '@/tools'
import Upload from '@/components/Upload'
import Save from '@/components/Save'
import Settings from '@/components/Settings'
import WandModal from '@/components/WandModal'
import { mapState } from 'vuex'
import { secondsToTime, timeToSeconds } from '@/util'

export default {
  components: {
    Settings,
    Save,
    Upload,
    Toolbar,
    Timeline,
    WandModal
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      isEditingTitle: false
    }
  },
  computed: {
    ...mapState(['numTriggers', 'currentTool', 'duration', 'fireDurations']),
    durationTime: {
      get() {
        return secondsToTime(this.duration)
      },
      set(v) {
        this.$store.commit('setDuration', timeToSeconds(v))
      }
    },
    triggersPerHour() {
      return Math.round(this.numTriggers * 3600 / this.duration)
    },
    fogDuration() {
      return (this.fireDurations.fog / 1000 * this.triggersPerHour).toFixed(2 )
    }
  },
  mounted() {
    window.addEventListener('keydown', (event) => {
      if (!event.target.tagName.match(/body/i)) {
        return
      }
      const { code, key, ctrlKey, metaKey, shiftKey } = event
      Object.entries(tools).forEach(([name, tool]) => {
        if (key.toLowerCase() === tool.shortcut) {
          this.$store.commit('setTool', name)
        }
      })
      if (ctrlKey || metaKey) {
        if (code === 'KeyZ') {
          if (shiftKey) {
            this.$store.commit('undo', -1)
          } else {
            this.$store.commit('undo', 1)
          }
        } else if (key === 'y') {
          this.$store.commit('undo', -1)
        }
      }
    })
  }
}
</script>

<style lang="scss">
html {
  //font-family: 'Roboto', 'sans-serif';
}
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  overflow: hidden;
}

header {
  display: flex !important;
  align-items: center;
  padding: 0 0.5rem;
  height: 50px;

}

.spacer {
  flex-grow: 1;
}

input[type="time"] {
  border: none;
}

footer {
  height: 18px;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  font-size: 0.9rem;

  h1 {
    font-size: 1rem;
    small {
      font-size: 0.65rem;
    }
  }

  span {
    font-size: 0.9rem;
    margin-top: 3px;
    margin-left: 2rem;
  }
}
</style>
