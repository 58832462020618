export function toTitle(str) {
  if (!str) {
    return ''
  }
  if (str.length <= 1) {
    return str
  }
  str = str.replace(/_|-/g, ' ')
  return str[0].toUpperCase() + str.slice(1).split(/(?=[A-Z])/).join(' ').toLowerCase()
}

export function snapTime(time, snapDuration) {
  return time - time % snapDuration
}

export function secondsToTime(duration, padStartMinutes = 2) {
  const seconds = `${duration % 60}`.padStart(2, '0')
  const minutes = `${Math.floor(duration / 60)}`.padStart(padStartMinutes, '0')
  return `${minutes}:${seconds}`
}

export function timeToSeconds(time) {
  const [minutes, seconds] = time.split(':')
  return +minutes * 60 + +seconds
}
