export const Tool = {
  Select: undefined,
  Pan: undefined,
  Add: undefined,
  Delete: undefined,
  Wand: undefined
}

Object.keys(Tool).forEach(k => { Tool[k] = k.toLowerCase() })

export const tools = {
  [Tool.Select]: { icon: 'selection', shortcut: 's' },
  [Tool.Pan]: { icon: 'pan', shortcut: 'p' },
  [Tool.Add]: { icon: 'pencil', shortcut: 'a' },
  [Tool.Delete]: { icon: 'eraser', shortcut: 'e' },
  [Tool.Wand]: { icon: 'auto-fix', shortcut: 'w' }
}

export const TriggerSpeed = {
  Fast: 'fast',
  Slow: 'slow'
}
