<template>
  <div>
    <div class="dropdown is-right" :class="{ 'is-active': display }">
      <div class="dropdown-trigger">
        <div class="button is-white" :class="{ 'is-active': display }"
             @click="display = !display">
          <mdicon name="cog" size="20" />
        </div>
      </div>
      <div class="dropdown-menu" id="dropdown-menu3" role="menu">
        <div class="dropdown-content p-4 durations">
          <p v-for="value, name in fireDurations" :key="name" class="field">
            <span>{{ $filters.toTitle(name) }}</span>
            <input type="number" class="input"
                   :value="value"
                   @change="change(name, $event)">
            <span>ms</span>
          </p>
          <hr class="dropdown-divider">
          <p class="field">
            <span>Frame rate</span>
            <input type="number" v-model="$store.state.frameRate"
                   class="input" min="1" max="44">
            <span>Hz</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Settings',
  computed: {
    ...mapState(['fireDurations'])
  },
  data() {
    return {
      display: false
    }
  },
  methods: {
    change(name, event) {
      const { value } = event.target
      this.$store.dispatch('updateDuration', { name, value })
    }
  }
}
</script>

<style scoped lang="scss">
table {
  td {
    vertical-align: middle;
  }
}
.modal-card {
  max-width: 320px;
}

.durations {
  .field {
    display: flex;
    align-items: baseline;
  }
  span:not(:last-child) {
    width: 5rem;
  }
  span:last-child {
    margin-left: 0.5rem;
  }
  input {
    width: 5rem;
  }
}
</style>
