import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'
import { toTitle } from '@/util'

const app = createApp(App)
  .use(store)
  .use(mdiVue, { icons: mdijs })

app.config.globalProperties.$filters = {
  toTitle
}

app.mount('#app')
