<template>
  <div class="track is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
    <div class="dropdown"
      :class="{ 'is-active': isActive }">
      <div class="dropdown-trigger" @click="toggleIsActive">
        <input v-if="isChangingNumber" type="number"
               v-model="trackNumber" ref="input"
               class="is-size-5"
               @keydown.enter="isChangingNumber = false" />
        <b v-else class="is-size-4 is-clickable">{{ trackNumber }}</b>
      </div>
      <div class="dropdown-menu track-menu" role="menu">
        <div class="dropdown-content">
          <a class="dropdown-item" @click="isChangingNumber = true">
            <mdicon name="rename-outline" size="18"></mdicon>
            <span>Rename</span>
          </a>
          <a class="dropdown-item" @click="deleteTrack">
            <mdicon name="delete-outline" size="18"></mdicon>
            <span>Delete</span>
          </a>
          <hr class="dropdown-divider">
          <a class="dropdown-item" @click="addTrack">
            <mdicon name="plus" size="18"></mdicon>
            <span>Insert below</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'TrackMenu',
  props: {
    trackIndex: Number
  },
  data() {
    return {
      isActive: false,
      isChangingNumber: false
    }
  },
  computed: {
    ...mapState(['cannonNumbers']),
    trackNumber: {
      get() {
        return this.cannonNumbers[this.trackIndex]
      },
      set(number) {
        const payload = { index: this.trackIndex, number }
        this.$store.dispatch('renameTrack', payload)
      }
    }
  },
  watch: {
    isChangingNumber(v) {
      if (v) {
        this.isActive = false
        this.$nextTick(() => {
          this.$refs.input.focus()
        })
      }
    }
  },
  methods: {
    toggleIsActive() {
      if (!this.isChangingNumber) {
        this.isActive = !this.isActive
      }
    },
    deleteTrack() {
      this.$store.dispatch('deleteTrack', this.trackIndex)
      this.$emit('redraw')
      this.isActive = false
    },
    addTrack() {
      this.$store.dispatch('addTrack', this.trackIndex)
      this.$emit('redraw')
      this.isActive = false
    }
  }
}
</script>

<style lang="scss" scoped>
input {
  width: 50px;
  border: none !important;

  &:focus-visible {
    border: none;
    outline: none;
  }
}
</style>

<style lang="scss">
div.track-menu {
  min-width: unset;

  .dropdown-item {
    padding: 0.25rem 0.75rem;
    fill: red;
    span.mdi {
      margin-right: 0.5rem;
      svg {
        fill: #666;
      }
    }
    span:last-child {
      padding-top: 3px;
    }
    display: flex;
    align-items: center;
  }
}
</style>
